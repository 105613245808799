<template>
  <base-page>
    <Filters model="cibles" color="per" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { Filters } from '../../components/filters'

export default {
  name: 'CiblesPage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - PER | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['cibles/getFilters']) {
      store.dispatch('cibles/fetchFilters')
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])

    this.$store.commit('pages/setPageTitle', this.$t('cibles.cibles_list'))

    this.$store.commit('pages/setTabs', [
      { name: 'list', label: 'Liste', to: { name: 'cibles-list' } },
      { name: 'map', label: 'Carte', to: { name: 'cibles-map' } },
      { name: 'timeline', label: 'Timeline', to: { name: 'cibles-timeline' } }
    ])
  }
}
</script>
